<template>
  <v-btn class="mx-2" :color="color" :disabled="disabled" @click="downloadResultCSV"
    >{{ labels.EXPORT_RESULT_CSV }}<v-icon>mdi-download</v-icon></v-btn
  >
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    rowSelected: {
      type: Array,
      default: null,
    },
    itemKey: {
      type: String,
      default: 'packing_no',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      labels: 'defined/labels',
    }),
  },
  methods: {
    downloadResultCSV() {
      this.$store.dispatch('common/downloadResultCSV', {
        rowSelected: this.rowSelected?.map((row) => row[this.itemKey]) ?? [],
        key: this.itemKey,
      });
    },
  },
};
</script>
